<template>
  <Card>
    <template #content>
      <div class="grid grid-nogutter surface-section text-800 container">
        <div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
          <section class="container">
            <span class="block text-5xl font-bold mb-1">Tonality Mastery: </span>
            <div class="text-5xl text-primary font-bold mb-3">Unleash Your Musical Potential</div>
            <p class="mt-0 mb-4 text-700 line-height-3">
              Welcome to our Tonality Trainer, your gateway to mastering musical tonalities.
              Explore and enhance your understanding of tonalities with our interactive and user-friendly platform.
              Sharpen your musical skills by learning to identify tonalities, scales, and more.
              Immerse yourself in the world of music theory with hands-on exercises and engaging challenges.
              Whether you're a beginner or an advanced musician, our Tonality Trainer is here to help you reach your
              musical goals.
            </p>

            <a :href="'/login'" class="p-button mr-3 p-button-raised" style="text-decoration: none;">Login</a>
            <a :href="'/register'" class="p-button p-button-outlined" style="text-decoration: none;">Register</a>
          </section>
        </div>
        <div class="col-12 md:col-6 overflow-hidden">
          <img :src="require('@/assets/gitar.jpeg')" alt="Image" class="md:ml-auto block md:h-full image"
               style="clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%); height: 300px; width: 700px;">
        </div>
      </div>
    </template>
  </Card>
</template>

<style lang="scss" scoped>
.container {
  border-radius: 5px;
}

.p-card {
  min-width: 618px;
}


.image {
  border-radius: 5px;
}

.p-card {
  text-align: center;
  width: 75%;
  margin: auto;
}
</style>
