import axios from "axios";
import Url from "@/models/Url";
import router from "@/router";
import {AllUserResponse, LoginResponse, Song} from "@/models/interface";
import {Availability} from "@/models/Constants";

const setAuthHeader = () => {
    const storage = localStorage.getItem("vuex");
    if (storage !== null) {
        const data = JSON.parse(storage);
        if (data.user !== null) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + data.user.token;
            return "Bearer " + data.user.token;
        } else {
            router.push("/login");
        }
    } else {
        throw new Error("Auth header cannot be set");
    }
};

const fetchData = async (path: string, config = {}) => {
    try {
        setAuthHeader();
        const response = await axios(path, config);
        return response.data;
    } catch (error: any) {
        if (error.response.status === 401) {
            router.push("/login");
        }
        throw error;
    }
};

export const Login = async (email: string, password: string): Promise<LoginResponse> => {
    // Do not put this through fetchData becuse Error responses will not be checked
    const data = {
        email: email,
        password: password
    };

    const result = await axios.post(Url.loginPath, data);

    if (result.status !== 200) {
        throw new Error();
    }

    return result.data;
};

export const Register = async (email: string, username: string, password: string): Promise<boolean> => {
    // Do not put this through fetchData becuse Error responses will not be checked
    const data = {
        email: email,
        password: password,
        username: username
    };
    const result = await axios.post(Url.registerPath, data);
    if (result.status !== 200) {
        throw new Error(result.data);
    }
    return true;
};

export const GetAllUsers = async (): Promise<AllUserResponse> => {
    return await fetchData(Url.usersPath);
};

export const DeleteSong = async (guid: string): Promise<boolean | Error> => {
    await fetchData(Url.deleteSongPath + guid, {
        method: "delete"
    });
    return true;
};

export const UpdateSong = async (song: Song): Promise<boolean | Error> => {
    const response: Song = await fetchData(Url.editVideoPath, {
        method: "put",
        data: song
    });
    if (response && typeof response === "object" && "videoId" in response) {
        return true;
    }
    throw new Error();
};

export const SetSongAvailability = async (available: Availability, guid: string): Promise<boolean> => {
    const response = await fetchData(Url.songAvailability, {
        method: "post",
        data: {
            guid: guid,
            availability: available
        }
    });
    return response.status == 200;
};

export const GetAllSong = async (): Promise<Song[]> => {
    return await fetchData(Url.getAllSongPath);
};

export const AddSongs = async (songs: string[]): Promise<Song> => {
    return await fetchData(Url.addSongPath, {
        method: "post",
        data: {titles: songs}
    });
};
