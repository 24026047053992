import {DeleteSong, SetSongAvailability, UpdateSong} from "@/Service/Api";
import {toastIt} from "@/Utils";
import {Song, YoutubeVideoQueryResult} from "@/models/interface";
import {ref} from "vue";
import axios from "axios";
import {ToastServiceMethods} from "primevue/toastservice";

const songs = ref<Song[]>([]);
const videoSelectorSong = ref<Song | undefined>();
const youtubeVideoList = ref<YoutubeVideoQueryResult[]>();
const selectedVideo = ref();
const op = ref();

export const useEditComposable = (toast: ToastServiceMethods, confirm: any) => {

    const UpdateVideoId = async (event: any) => {
        const sungUnderEdit = songs.value.filter((song: any) => song.guidId === videoSelectorSong.value!.guidId)[0];
        sungUnderEdit.videoId = event.data.id.videoId;
        const songPayload = JSON.parse(JSON.stringify(sungUnderEdit));
        try {
            if (await UpdateSong(songPayload)) {
                const actualSong = songs.value.filter((song: any) => song.guidId === videoSelectorSong.value!.guidId)[0];
                actualSong.videoId = event.data.id.videoId;
                await MarkAsAvailable(actualSong.guidId);
                toastIt(toast, "success", `${event.data.id.videoId} has been assigned to ${event.data.snippet.title}`, 3000);
                op.value.toggle();
            }
        } catch (e) {
            toastIt(toast, "error", "Already exists or something went wrong", 3000);
        }
    };

    const MarkAsAvailable = async (guid: string) => {
        await SetSongAvailability(0, guid);
        const song = songs.value.filter((s: any) => s.guidId == guid)[0];
        song.unavailable = 0;
    };

    const ConfirmDelete = (event: any, guid: string, name: string) => {
        confirm.require({
            blockScroll: undefined,
            target: event.currentTarget,
            message: "Are you want delete this song?\n",
            icon: "pi pi-logout",
            accept: async () => {
                try {
                    await DeleteSong(guid);
                    toastIt(toast, "success", `${name} has been deleted`, 3000);
                    songs.value = songs.value.filter((song: any) => song.guidId !== guid);
                } catch (e) {
                    toastIt(toast, "error", "Song deletion failed", 6000);
                }
            }
        });
    };

    const ConfirmReset = async (event: any, guid: string) => {
        confirm.require({
            target: event.currentTarget,
            message: "Mark this song as available?",
            icon: "pi pi-exclamation-triangle",
            accept: async () => {
                await SetSongAvailability(0, guid);
                const song = songs.value.filter((s: any) => s.guidId == guid)[0];
                song.unavailable = 0;
            }
        });
    };

    const UpdateVideoSelector = async (song: Song) => {
        youtubeVideoList.value = [];
        videoSelectorSong.value = undefined;
        selectedVideo.value = null;
        videoSelectorSong.value = song;
        const data = {
            query: videoSelectorSong.value.name,
            maxResults: 2
        };
        const result = await axios.post("/api/Youtube", data);
        if (result.status === 200) {
            youtubeVideoList.value = result.data;
            selectedVideo.value = result.data.filter((result: any) => result.id.videoId == videoSelectorSong.value!.videoId)[0];
        }
    };

    const EditComplete = async (event: any) => {

        let {data, newValue, field, index, newData} = event;

        //to reload old values if update fails
        const songUnderEditJSON = JSON.stringify(songs.value.filter((song: Song) => song.guidId === data.guidId)[0]);
        const songUnderEdit = JSON.parse(songUnderEditJSON);

        if (data[field] === newValue) return false; // value didnt change
        switch (field) {
            case "name":
                if (newValue.length === 0) {
                    event.preventDefault();
                }
                data[field] = newValue;
                break;
            case "playStart":
                if (newValue.length === 0) {
                    event.preventDefault();
                }
                data[field] = newValue;
                break;
            case "genre":
                data[field] = newValue;
                break;
            case "tonality":
                data[field] = newValue;
                break;
            case "scale":
                data[field] = newValue;
                break;
            case "baseNote":
                data[field] = newValue;
                break;
            case "scaleType":
                data[field] = newValue;
                break;
            default:
                event.preventDefault();
                break;
        }

        try {
            const result = await axios.put("/api/Song", newData);
            if (result.status === 202) {
                toastIt(toast, "success", "Song has been updated successfully", 3000);
                return true;
            } else {
                data[field] = songUnderEdit[field];
                toastIt(toast, "error", result.data[0].errorMessage, 3000);
            }
        } catch (e) {
            data[field] = songUnderEdit[field];
            toastIt(toast, "error", (e as Error).message, 3000);
        }
    };

    const Toggle = async (song: Song) => {
        op.value.toggle(event);
        await UpdateVideoSelector(song);
    };

    const ClearSongs = () => songs.value = [];


    return {
        songs,
        videoSelectorSong,
        confirm,
        youtubeVideoList,
        selectedVideo,
        op,
        UpdateVideoId,
        MarkAsAvailable,
        ConfirmDelete,
        ConfirmReset,
        UpdateVideoSelector,
        EditComplete,
        Toggle,
        ClearSongs
    };
};
