export const FormatDate = (date: string) => {
    return new Date(date).toLocaleDateString("hu-HU");
};

export const toastIt = (toast: any, type: "success" | "info" | "warning" | "error", details: string, life: number) => {
    let summary;
    switch (type) {
        case "info":
            summary = "Info";
            break;
        case "error":
            summary = "Error";
            break;
        case "warning":
            summary = "Warning";
            break;
        case "success":
            summary = "Success";
            break;
    }
    toast.add({
        severity: type,
        summary: summary,
        detail: details,
        life: life
    });
};
