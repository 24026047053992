import {createRouter, createWebHistory} from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginForm from "@/components/LoginForm.vue";
import RegisterForm from "@/components/RegisterForm.vue";
import LogoutRedirect from "@/components/LogoutRedirect.vue";
import SongList from "@/views/SongList.vue";
import AddSongs from "@/views/AddSongs.vue";
import TestEmbed from "@/views/TestEmbed.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomeView
    },
    {
        path: "/login",
        name: "Login",
        component: LoginForm
    },
    {
        path: "/register",
        name: "Register",
        component: RegisterForm
    },
    {
        path: "/logout",
        name: "Logout",
        component: LogoutRedirect
    },
    {
        path: "/songlist",
        name: "SongList",
        component: SongList,
    },
    {
        path: "/addsongs",
        name: "AddSongs",
        component: AddSongs,
    },
    {
        path: "/testEmbed/:videoId",
        name: "TestEmbed",
        component: TestEmbed,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
