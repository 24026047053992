const Url = {
    loginPath: "/api/Auth/login",
    registerPath: "/api/Auth/register",
    usersPath: "/api/User",
    unavailableSongs: "/api/Song/unavailable",
    addSongPath: "/api/Song/ImportMany",
    songAvailability: "/api/Song/availability",
    deleteSongPath: "/api/Song/",
    getAllSongPath: "/api/Song/",
    editVideoPath: "/api/Song/",
};

export default Url;