<template>
  <div class="footerContainer">
    <div class="footerContent">
      <ul class="links">
        <li class="flex-item"><a href="#">About Us</a></li>
        <li class="flex-item"><a href="#">News</a></li>
        <li class="flex-item"><a href="#">Careers</a></li>
        <li class="flex-item"><a href="#">Investor Relations</a></li>
        <li class="flex-item"><a href="#">Media Kit</a></li>
      </ul>
      <ul class="social">
        <li><a href="https://github.com/4dd4m/SongApi"><i class="pi pi-github social-logo" /></a></li>
        <li><a href="https://www.linkedin.com/in/adam-torok-a11789210/"><i class="pi pi-linkedin social-logo" /></a></li>
        <li><a href="https://discord.gg/DBVPZm8F7"><i class="pi pi-discord social-logo" /></a></li>
      </ul>
      <ul class="links">
        <li><a href="#" />Privacy Policy</li>
        <li><a href="#" />Terms of Service</li>
      </ul>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.footerContainer {
  text-align: center;
  display: flex;
  margin: auto;
  color: var(--text-color-text);
  min-width: 680px;
  max-width: 700px;
}

.footerContent {
  width: 100%;
  margin: 30px auto;
  border-top: 1px solid var(--gray-400);
  font-size: 0.75rem;
  font-weight: normal;
  color: var(--primary-color);
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  text-align: center;

  & a {
    color: var(--primary-color);
    text-decoration: none;
    flex-wrap: wrap;

    & i {
      margin-top: 10px;
    }
  }

  & a:visited {
    color: var(--primary-color);
  }

  & a:hover {
    color: var(--text-color-secondary);
  }

  & ul {
    list-style-type: none;
    margin: 20px auto;
    justify-content: space-around;
    width: 100%;
  }

  & .links {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    flex-wrap: wrap;

    & li a {
      margin-top: 10px !important;
    }
  }

  & .social {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 30%;
  }

  .social-logo {
    font-size: 1.5rem;
    width: 50px;
  }

}
</style>
