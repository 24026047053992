import {Note, Tonality} from "@/models/Constants";

export const NoteOptions = [
    {label: Note[Note.Uncategorized], value: 0},
    {label: Note[Note.C], value: 1},
    {label: Note[Note.Db], value: 2},
    {label: Note[Note.D], value: 3},
    {label: Note[Note.Eb], value: 4},
    {label: Note[Note.E], value: 5},
    {label: Note[Note.F], value: 6},
    {label: Note[Note.Fs], value: 7},
    {label: Note[Note.G], value: 8},
    {label: Note[Note.Ab], value: 9},
    {label: Note[Note.A], value: 10},
    {label: Note[Note.B], value: 11},
    {label: Note[Note.H], value: 12},
];

export const TonalityOptions = [
    {label: Tonality[Tonality.Uncategorized], value: Tonality.Uncategorized},
    {label: Tonality[Tonality.Major], value: Tonality.Major},
    {label: Tonality[Tonality.Minor], value: Tonality.Minor}
];

export const GenreOptions = [
    {label: "N/A", value: 0},
    {label: "Hungarian", value: 1},
    {label: "Classical", value: 2},
    {label: "English", value: 3},
];

export const GetGenre = (genre: number) => {
    switch (genre) {
        case 0:
            return "N/A";
        case 1:
            return "Hungarian";
        case 2:
            return "Classical";
        case 3:
            return "English";
    }
};

export const GetTonality = (tonality: number) => {
    switch (tonality) {
        case 0:
            return "N/A";
        case 1:
            return "Major";
        case 2:
            return "Minor";
    }
};

export const GetBaseNote = (note: number) => {
    switch (note) {
        case 0:
            return "N/A";
        case 1:
            return "C";
        case 2:
            return "Db";
        case 3:
            return "D";
        case 4:
            return "Eb";
        case 5:
            return "E";
        case 6:
            return "F";
        case 7:
            return "F#";
        case 8:
            return "G";
        case 9:
            return "Ab";
        case 10:
            return "A";
        case 11:
            return "B";
        case 12:
            return "H";
        default:
            return "N/A";
    }
};