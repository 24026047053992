export const PlayerAttachDelay = 0;

export enum Availability {
    Available,
    Unavailable
}

export enum EmptyHistoryTonality {
    All,
    Major,
    Minor
}

export enum GenreType {
    Uncategorized,
    Hungarian,
    Classical,
    English
}

export enum Note {
    Uncategorized,
    C,
    Db,
    D,
    Eb,
    E,
    F,
    Fs,
    G,
    Ab,
    A,
    B,
    H
}

export enum ScaleType {
    Uncategorized,
    Ionian,
    Dorian,
    Phrygian,
    Lydian,
    Mixolydian,
    Aeloian,
    Locrian
}

export enum Tonality {
    Uncategorized,
    Major,
    Minor
}

export enum UserRole {
    User,
    Admin
}

export enum UserStatus {
    Inactive,
    Active,
    Blocked,
    Hidden
}

export enum VerifyTonalityType {
    Major,
    Minor
}
